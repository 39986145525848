import React from "react";

function Button(props) {
  return (
    <p className="col-auto">
      <button
        className="button"
        onClick={() => props.handleClick(props.children)}
      >
        {props.children}
      </button>
    </p>
  );
}

export default Button;
