import React from "react";

function EqualsButton(props) {
  return (
    <p className="col-auto">
      <button
        className="button btn--yellow"
        onClick={() => props.handleSolve(props.children)}
      >
        {props.children}
      </button>
    </p>
  );
}

export default EqualsButton;
