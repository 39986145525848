import React, { useState, useEffect } from "react";

import Popup from "reactjs-popup";

import Button from "./Button/Button";
import ClearButton from "./ClearButton";
import EqualsButton from "./EqualsButton/EqualsButton";

import axios from "axios";

import crypto_1inch from "../imgs/cryptoicons-master/SVG-icons/1inch.svg";
import crypto_btc from "../imgs/cryptoicons-master/SVG-icons/btc.svg";
import crypto_eth from "../imgs/cryptoicons-master/SVG-icons/eth.svg";
import crypto_rune from "../imgs/cryptoicons-master/SVG-icons/rune.svg";
import crypto_sushi from "../imgs/cryptoicons-master/SVG-icons/sushi.svg";
import crypto_shib from "../imgs/Shiba_Inu_coin_logo.png";

const client = axios.create({
  baseURL: "https://api.coingecko.com/api/v3",
});

function CurrencySymbol({ currency }) {
  if ("usd" === currency) {
    return "🇺🇸"; //💲
  } else {
    return "🇨🇳"; //cny ¥
  }
}

function Calculator(props) {
  const [input, setInput] = useState("");
  const [currency, setCurrency] = useState("usd");
  const [prevNum, setPrevNum] = useState("");
  const [curNum, setCurNum] = useState("");
  const [operator, setOperator] = useState("");

  const clickButton = (e) => {
    if (e === "+" || e === "-" || e === "/" || e === "*") {
      setOperator(e);
      console.log(e);
    } else {
      setInput(input + e);
      console.log(input);
    }
  };

  async function getPrice(coin) {
    const response = await client.get(
      `/simple/price?ids=${coin}&vs_currencies=${currency}`
    );
    //TODO: nil data wrapping
    //console.log(response);
    const { data } = response;
    const price = data[coin][currency];

    setInput(price);
  }

  async function rotateCurrency() {
    const currencies = ["usd", "cny", ""];

    var nextCurrency = "usd";
    if ("usd" === currency) {
      nextCurrency = "cny";
    } else {
      nextCurrency = "usd";
    }
    setCurrency(nextCurrency);
  }

  const handleClear = () => {
    setInput("");
    setPrevNum("");
    setCurNum("");
    setOperator("");
  };
  const addZeroToInput = (e) => {
    if (input !== "") {
      setInput(input + e);
      console.log(input);
    }
  };
  const addDecimalToInput = (e) => {
    if (input.indexOf(".") === -1) {
      setInput(input + e);
      console.log(input);
    }
  };
  const solve = () => {
    setCurNum(input);
  };

  useEffect(() => {
    const math_it_Up = {
      "+": function (x, y) {
        return x + y;
      },
      "-": function (x, y) {
        return x - y;
      },
      "*": function (x, y) {
        return x * y;
      },
      "/": function (x, y) {
        return x / y;
      },
    };
    if (curNum !== "") {
      console.log(curNum);
      if (operator !== "") {
        //let solution = Math.floor(parseFloat(prevNum) + parseFloat(curNum));
        let solution = math_it_Up[operator](
          parseFloat(prevNum),
          parseFloat(curNum)
        );
        setInput(solution);
        setOperator("");
        setCurNum("");
      }
    }
  }, [curNum, operator, prevNum]);
  function operation_Func(e) {
    setPrevNum(input);
    setInput("");
    setOperator(e);
  }

  var currencyTextSymbol = "usd" === currency ? "$" : "¥";

  return (
    <>
      <main className="main">
        <div className="card calc-body">
          <div className="card-body">
            <span className="card-title logo">🐲 Calc</span>
            <div className="screen mx-auto">
              <div className="screen__inner">
                <input
                  type="text"
                  readOnly
                  className="output"
                  value={input}
                  placeholder="0"
                />
              </div>
            </div>
          </div>

          <div className="container">
            <Button handleClick={() => getPrice("bitcoin")}>
              <img src={crypto_btc} />
            </Button>

            <Button handleClick={() => getPrice("ethereum")}>
              <img src={crypto_eth} />
            </Button>
            <Button handleClick={() => getPrice("sushi")}>
              <img src={crypto_sushi} />
            </Button>
            <Button handleClick={() => getPrice("shiba-inu")}>
              <img src={crypto_shib} className="cryptoicon" />
            </Button>
            <Button handleClick={clickButton}>9</Button>

            <Button handleClick={() => rotateCurrency()}>
              {" "}
              <CurrencySymbol currency={currency} />{" "}
            </Button>
            <Button handleClick={clickButton}>7</Button>
            <Button handleClick={clickButton}>8</Button>
            <Button handleClick={clickButton}>9</Button>
            <Button handleClick={operation_Func}>/</Button>

            <Button handleClick={clickButton}></Button>
            <Button handleClick={clickButton}>4</Button>
            <Button handleClick={clickButton}>5</Button>
            <Button handleClick={clickButton}>6</Button>
            <Button handleClick={operation_Func}>*</Button>

            <Button handleClick={clickButton}>%</Button>
            <Button handleClick={clickButton}>3</Button>
            <Button handleClick={clickButton}>2</Button>
            <Button handleClick={clickButton}>1</Button>
            <Button handleClick={operation_Func}>-</Button>

            <ClearButton handleClear={handleClear}>CE</ClearButton>
            <Button handleClick={addZeroToInput}>0</Button>
            <Button handleClick={addDecimalToInput}>.</Button>
            <EqualsButton handleSolve={solve}>=</EqualsButton>
            <Button handleClick={operation_Func}>+</Button>
          </div>
        </div>
      </main>
    </>
  );
}

export default Calculator;
